
/* ==== editable-form ==== */

/* class for single editable element */
.editable-wrap {
    display: inline-block;
  /*  white-space: pre;*/
    margin: 0;
    width: 100%;
}

/* remove bottom-margin for bootstrap */
.editable-wrap .editable-controls,
.editable-wrap .editable-error {
    margin-bottom: 0;
}

/* remove bottom-margin of inputs */
.editable-wrap .editable-controls > input,
.editable-wrap .editable-controls > select,
.editable-wrap .editable-controls > textarea {
    margin-bottom: 0;
}

/* keep buttons on the same line */
.editable-wrap .editable-input {
    display: inline-block;
}

.editable-buttons {
    display: inline-block;
    vertical-align: top;
}

.editable-buttons button {
    margin-left: 5px;
}

/* in bootstrap width: 100% => buttons go outside the box */
.editable-input.editable-has-buttons {
    width: auto;
}


/* ==== editable-bsdate ==== */

/* fix padding issue on bsdate popup */
.editable-bsdate {
    white-space: nowrap;
}

/* ==== editable-bstime ==== */

/* fix padding issue on bstime */
.editable-bstime {
    white-space: nowrap;
}

/* workaround for bootstrap that sets width: 100% and inputs become too wide */
.editable-bstime .editable-input input[type="text"] {
    width: 46px;
}

/* less padding for .well */
.editable-bstime .well-small {
    margin-bottom: 0;
    padding: 10px;
}

/* ==== editable-range ==== */

.editable-range output {
    display: inline-block;
    min-width: 30px;
    vertical-align: top;
    text-align: center;
}

/* ==== editable-color ==== */

.editable-color input[type="color"] {
    width: 50px;
}


/* ==== editable-checkbox ==== */
/* ==== editable-checklist ==== */
/* ==== editable-radiolist ==== */

.editable-checkbox label span,
.editable-checklist label span,
.editable-radiolist label span {
    margin-left: 7px;
    margin-right: 10px;
}

/* ==== element ==== */

/* hiding element */
.editable-hide {
    display: none !important;
}

.editable-click,
a.editable-click {
    text-decoration: none;
    color: #428bca;
    border-bottom: dashed 1px #428bca;
}

.editable-click:hover,
a.editable-click:hover {
    text-decoration: none;
    color: #2a6496;
    border-bottom-color: #2a6496;
}

/* editable-empty */
.editable-empty,
.editable-empty:hover,
.editable-empty:focus,
a.editable-empty,
a.editable-empty:hover,
a.editable-empty:focus {
    font-style: italic;
    color: #DD1144;
    text-decoration: none;
}

/* editable popover */
.popover-wrapper > a {
    /* make the link always show up */
    display: inline !important;
}

.popover-wrapper {
    /* make absolutely positioned children constrained to this box*/
    display: inline;
    position: relative;
}

.popover-wrapper form {
    position: absolute;
    top: -53px;
    background: #FFF;
    border: 1px solid #AAA;
    border-radius: 5px;
    padding: 7px;
    width: auto;
    display: inline-block;
    left: 50%;
    z-index: 101;
}

.popover-wrapper form:before {
    content:"";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #AAA;
    position:absolute;
    bottom:-10px;
}

.popover-wrapper form:after {
    content:"";
    width:0;
    height:0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #FFF;
    position:absolute;
    bottom:-9px;
}


@media screen and (max-width: 750px) {
    .popover-wrapper form {
        margin-left: -60px;
    }

    .popover-wrapper form:before {
        left:50px;
    }

    .popover-wrapper form:after {
        left:51px;
    }
}

@media screen and (min-width: 750px) {
    .popover-wrapper form {
        margin-left: -110px;
    }

    .popover-wrapper form:before {
        left:100px;
    }

    .popover-wrapper form:after {
        left:101px;
    }
}
